import PropTypes from "prop-types"
import React from "react"

import CookieBanner from '../cookieBanner'
import Footer from "../footer"
import Header from "../header"
import styles from './index.module.scss'

const Layout = ({ children }) => (
  <>
    <Header />
    <main className={styles.main}>{children}</main>
    <Footer />
    <CookieBanner />
  </>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
