import React from "react";
import { Link, StaticQuery, graphql } from 'gatsby';

import Container from '../container';
import { pathTo } from '../../routes';

import styles from './index.module.scss';
import logo from '../../assets/images/logo-white.png';
import logo2x from '../../assets/images/logo-white@2x.png';

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <Container>
        <div className={styles.row}>
          <div className={styles.news}>
            <div className={styles.headline}>Über uns</div>
            <div className={styles.text}>Wir stehen für eine sorgenfreie digitale Zukunft.<br/>&mdash;<br/>Dank unserer digitalen Infrastruktur können wir effizente Prozesse abbilden. Der regelmäßige persönliche Kontakt mit unseren Mandanten hat trotzdem oberste Priorität.</div>
            <div className={styles.logo}>
              <img
                src={logo}
                srcSet={`${logo2x} 2x`}
                alt='Malburg & Rost - Steuerberatung in Chemnitz'
                title="Malburg & Rost - Steuerberatung in Chemnitz"
              />
            </div>
          </div>
          <div className={styles.info}>
            <div className={styles.contact}>
              <div className={styles.headline}>Malburg und Rost Partnerschaft</div>
              <div className={styles.text}>
                Steuerberatungsgesellschaft<br/>
                Reichsstraße 22<br/>
                09112 Chemnitz
              </div>
              <div className={styles.headline}>Telefon</div>
              <div className={styles.text}>+49 371 / 3 81 55 – 0</div>
              <div className={styles.headline}>E-Mail</div>
              <div className={styles.text}>info@malburg-chemnitz.de</div>
            </div>
            <div className={styles.navigation}>
              <StaticQuery
                query={graphql`
                  query MetaNavigationQuery {
                    meta: contentfulNavigationZone(title: {eq: "Meta"}) {
                      items {
                        __typename
                        ... on ContentfulPage {
                          id
                          slug
                          title
                        }
                      }
                    }
                  }
                `}
                render={data => {
                  if (data === undefined || data.meta === undefined) return null;

                  return [...data.meta.items].map(item => (
                    <Link
                      key={item.id}
                      to={pathTo(item)}
                      className={styles.link}
                    >{item.title}</Link>
                  ))
                }}
              />
            </div>
          </div>
        </div>
      </Container>
    </footer>
  )
};

export default Footer;
